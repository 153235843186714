<template>
    <div>
      <b-card>
        <h6 class="mb-4 font-weight-bold">Filter</h6>
        <b-row align-v="center">
          <b-col>
            <b-form-group
              label="Employee"
            >
              <b-form-input
                v-model="filter.fullname"
                placeholder="Select employee"
                @change="$refs.table.refresh()"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Client"
            >
              <custom-select
                v-model="filter.clientName"
                label="name"
                placeholder="Select client"
                :options="clientList"
                @input="$refs.table.refresh()"
                :reduce="({ name }) => name"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Status"
            >
              <custom-select
                v-model="filter.status"
                label="name"
                placeholder="Select status"
                :options="statusList"
                @input="$refs.table.refresh()"
                :reduce="({ value }) => value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <div class="button-project mt-2 d-flex justify-content-end">
              <b-button
                class="reset mr-3"
                variant="outline-warning"
                @click="resetFilter"
              >
                Reset Filter
              </b-button>
              <b-button
                class="next"
                variant="warning"
                @click="$refs.table.refresh()"
              >
                Apply Filter
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="mt-3" body-class="py-4 px-3">
        <div class="mt-3 d-flex justify-content-between">
          <b-button
            class="next"
            variant="warning"
            v-b-modal.modal-developer
          >
            Add New Developer Rate
          </b-button>
          <b-form-group class="m-0">
            <b-input-group>
              <b-form-input
                v-model="search"
                placeholder="Search..."
                @change="$refs.table.refresh()"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <br/>
        <b-row v-show="!items.length">
          <b-col>
            <div align="center">
              <img src="@/assets/icon-no-invoice.png"/>
            </div>
          </b-col>
        </b-row>
        <b-table
          ref="table"
          v-show="items.length"
          :items="fetchDeveloperList"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          striped
          responsive
        >
          <template #cell(no)="{ index }">
            {{ index + 1 }}
          </template>
          <template #cell(employeeName)="{ item }">
            {{ item.fullname }}
          </template>
          <template #cell(clientName)="{ item }">
            {{ item.clientName || '-' }}
          </template>
          <template #cell(ratePerDays)="{ item }">
            {{ formatMoney(item.ratePerDay, {
              symbol: "Rp.",
              precision: 0,
              thousand: ".",
            }) }}
          </template>
          <template #cell(action)="{ item }">
            <div class="data">
              <b-link
                class="text-secondary mx-2"
                @click="goToEdit(item.id)"
              >
                <fa-icon icon="edit" />
              </b-link>
              <b-link
              class="text-secondary mx-2"
              @click="goToDelete(item)"
            >
              <fa-icon icon="trash-alt" />
            </b-link>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-between">
          <div>
            <span>Show</span>
            <b-form-select
              v-model="perPage"
              :options="pageOptions"
              @change="$refs.table.refresh()"
              class="page-option ml-2 mr-2 w-auto"
              size="sm"
            />
            <span>Entries</span>
          </div>
          <div>
            <b-pagination
              class="mb-0"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              pills
              hide-goto-end-buttons
              @input="$refs.table.refresh()"
            />
            <span class="show-entries mr-auto">
              {{`Show ${totalRows === 0 ? 0 : ((currentPage-1) * perPage) + 1} to ${totalRows > ((currentPage) * perPage) ? ((currentPage) * perPage) : totalRows} of ${totalRows} entries`}}
            </span >
          </div>
        </div>
      </b-card>
      <modal-developer
        :is-edit="isEdit"
        :form="form"
        @reset="resetForm"
        @submit="submitDeveloperRate"
      />
      <modal-delete-developer
      :developer-name="formDeleted.developerName"
      @remove="removeDeveloper"
    />
    </div>
  </template>

<script>
import api from '@/api'
import { formatMoney } from 'accounting-js'
import ModalDeveloper from './ModalDeveloper.vue'
import ModalDeleteDeveloper from './ModalDeleteDeveloper.vue'

export default {
  components: {
    ModalDeveloper,
    ModalDeleteDeveloper
  },
  data: () => ({
    sortBy: '',
    search: '',
    filter: JSON.parse(localStorage.getItem('filter')) || {
      fullname: null,
      clientName: null,
      status: null
    },
    items: [],
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'employeeName', label: 'Employee Name', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'clientName', label: 'Client Name', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'ratePerDays', label: 'Rate Per Day', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ],
    listDeveloper: [
      {
        employee: {
          id: '123248325834593458444',
          fullname: 'Azhar Bani Rashif'
        },
        client: {
          id: '',
          name: 'ITM'
        },
        ratePerDays: 0,
        status: true
      },
      {
        employee: {
          employeeId: '1343536545353435345',
          fullname: 'Reza Muharam'
        },
        client: null,
        ratePerDays: 0,
        status: false
      }
    ],
    pageOptions: [10, 20, 50, 100],
    form: {
      employee: null,
      client: null,
      ratePerDays: 0,
      status: true
    },
    isEdit: false,
    employeeList: [],
    clientList: [],
    statusList: [
      { name: 'Internal', value: true },
      { name: 'External', value: false }
    ],
    formDeleted: {
      id: '',
      developerName: ''
    }
  }),

  computed: {
    stringifiedFilters() {
      let str = `search@=*${this.search}`
      for (const key in this.filter) {
        if (key === 'status' && this.filter[key] !== null) str += `, ${key}==${this.filter[key]}`
        else if (this.filter[key]) str += `, ${key}@=*${this.filter[key]}`
      }
      return str
    }
  },

  created() {
    this.fetchEmployeeList('')
    this.fetchClientList()
  },

  methods: {
    formatMoney,
    async fetchDeveloperList() {
      localStorage.setItem('filter', JSON.stringify(this.filter))
      const { data } = await api.developer.list({
        page: this.currentPage,
        pageSize: this.perPage,
        filters: this.stringifiedFilters,
        sorts: this.sortBy
      })
      this.items = data.data.length !== 0 ? data.data.data : []
      this.totalRows = data.data.length !== 0 ? data.data.totalData : 0
      this.totalPage = data.data.totalPage
      this.items.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.items
    },
    async fetchEmployeeList(key) {
      const { data } = await api.employee.list({
        page: 1,
        pageSize: 10,
        Filters: key ? `Status==True, fullName@=*${key}` : 'Status==True'
      })

      this.employeeList = data.data.length ? data.data : []
    },
    async fetchClientList() {
      const { data } = await api.client.list()
      this.clientList = data.data.length ? data.data : []
    },
    resetFilter() {
      this.filter = {}
      this.search = ''
      localStorage.removeItem('filter')
      this.$refs.table.refresh()
    },
    async submitDeveloperRate(developerData) {
      try {
        if (developerData.id) {
          await api.developer.update(developerData.form, developerData.id)
        } else {
          await api.developer.add(developerData.form)
        }
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast(this.isEdit ? 'Success update developer rate.' : 'Success save developer rate.', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        setTimeout(() => {
          this.$bvModal.hide('modal-developer')
          this.$refs.table.refresh()
          this.isEdit = false
        }, 2000)
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(error.data ? error.data.messageEng : error.data, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    async goToEdit(developerId) {
      this.isEdit = true
      const { data } = await api.developer.get(developerId)
      this.form = {
        id: data.data.id,
        employee: data.data.employeeId ? {
          id: data.data.employeeId,
          fullName: data.data.fullname
        } : data.data.fullname,
        client: data.data.clientId && data.data.clientName ? {
          id: data.data.clientId,
          name: data.data.clientName
        } : null,
        status: data.data.status,
        ratePerDay: data.data.ratePerDay
      }
      this.$bvModal.show('modal-developer')
    },
    goToDelete(item) {
      this.formDeleted = {
        id: item.id,
        developerName: item.fullname
      }

      this.$bvModal.show('modal-delete-developer')
    },
    async removeDeveloper() {
      try {
        await api.developer.remove(this.formDeleted.id)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success remove developer rate.', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        setTimeout(() => {
          this.$bvModal.hide('modal-delete-developer')
          this.$refs.table.refresh()
        }, 2000)
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    resetForm() {
      this.form = this.$options.data().form
      this.isEdit = false
    }
  },

  destroyed() {
    localStorage.removeItem('filter')
  }
}
</script>

  <style lang="scss" scoped>
  .col, .col-2, .col-3, .col-6 {
    padding: 0 16px;
  }
  </style>
