<template>
    <b-modal
      id="modal-developer"
      :title="`${isEdit ? 'Edit' : 'New'} Developer Rate`"
      hide-footer
      centered
      @hidden="resetModal"
    >
      <validation-observer ref="form" slim>
        <b-form-group
          label="Employee Status"
          v-slot="{ ariaDescribedby }"
          class="d-flex"
        >
          <b-form-radio
            v-model="form.status"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            @change="form.employee = null"
            :disabled="isEdit"
            v-b-tooltip.hover title="Active employee under CV Garuda Infinity Kreasindo"
            :value="true"
          >
            Internal
          </b-form-radio>
          <b-form-radio
            v-model="form.status"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            @change="form.employee = null"
            :disabled="isEdit"
            v-b-tooltip.hover title="Outsourced employee of CV Garuda Infinity Kreasindo"
            :value="false"
          >
            External
          </b-form-radio>
        </b-form-group>
        <validation-provider
          v-if="form.status"
          name="Employee"
          :rules="form.status ? 'required' : ''"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Employee*"
            :invalid-feedback="errors[0]"
          >
            <custom-select
              v-model="form.employee"
              label="fullName"
              placeholder="Select employee"
              @search="fetchListEmployee($event)"
              :options="employeeList"
              :disabled="isEdit"
              :class="{ 'is-invalid': errors.length }"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-if="!form.status"
          name="Employee"
          :rules="!form.status ? 'required' : ''"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Employee*"
            :invalid-feedback="errors[0]"
          >
            <b-form-input
              placeholder="Enter name"
              v-model="form.employee"
              trim
              :class="{ 'is-invalid': errors.length }"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-if="!form.status"
          name="Client"
          :rules="!form.status ? 'required' : ''"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Client*"
            :invalid-feedback="errors[0]"
          >
            <custom-select
                v-model="form.client"
                label="name"
                placeholder="Select client"
                :options="clientList"
                :reduce="({ id }) => id"
                :class="{ 'is-invalid': errors.length }"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
            name="Rate Per Day"
            rules="required|min_value:0"
            v-slot="{ errors }"
          >
            <b-form-group label="Rate Per Day*" :invalid-feedback="errors[0]">
              <b-input-group
                prepend="Rp"
                :class="{ 'is-invalid': errors.length }"
              >
                <money
                  class="form-control"
                  :class="{ 'is-invalid': errors.length }"
                  v-model="form.ratePerDay"
                  placeholder="0"
                />
              </b-input-group>
            </b-form-group>
          </validation-provider>
        <b-button v-if="!isEdit" class="next" variant="warning" block @click="submit">
          Save
          <b-spinner
            v-if="isBusy"
            class="ml-1"
            label="Spinning"
            small
          />
        </b-button>
        <div v-else class=" d-flex justify-content-center">
          <b-button
            class="text-warning mt-2"
            variant="outline-warning"
            @click="cancel"
            block
          >
            Cancel
          </b-button>
          <b-button
            class="next ml-3"
            variant="warning"
            @click="submit"
            block
          >
            Update
            <b-spinner
                v-if="isBusy"
                class="ml-1"
                label="Spinning"
                small
            />
          </b-button>
        </div>
      </validation-observer>
    </b-modal>
  </template>

<script>
import api from '@/api'

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: () => false
    },
    form: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    isBusy: false,
    employeeList: [],
    clientList: []
  }),

  created() {
    this.fetchListEmployee('')
    this.fetchClientList()
  },

  methods: {
    async fetchListEmployee(key) {
      const { data } = await api.employee.list({
        page: 1,
        pageSize: 10,
        Filters: key ? `Status==True, fullName@=*${key}` : 'Status==True'
      })

      this.employeeList = data.data.length ? data.data : []
    },
    async fetchClientList() {
      const { data } = await api.client.list()
      this.clientList = data.data.length ? data.data : []
    },
    async submit() {
      this.isBusy = true
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.isBusy = false
        return false
      }

      const form = {
        clientId: this.form.client,
        employeeId: this.form.employee.id || null,
        fullname: this.form.employee.fullName || this.form.employee,
        status: this.form.status,
        ratePerDay: this.form.ratePerDay
      }
      if (this.isEdit) form.id = this.form.id

      this.$emit('submit', { id: this.form.id || null, form: form })
      this.isBusy = false
    },
    resetModal() {
      this.$emit('reset')
    },
    cancel() {
      this.$bvModal.hide('modal-developer')
    }
  }
}
</script>

  <style lang="scss" scoped>
  </style>
<template>
    <b-modal
      id="modal-developer"
      :title="`${isEdit ? 'Edit' : 'New'} Developer Rate`"
      hide-footer
      centered
      @hidden="resetModal"
    >
      <validation-observer ref="form" slim>
        <b-form-group
          label="Employee Status"
          v-slot="{ ariaDescribedby }"
          class="d-flex"
        >
          <b-form-radio
            v-model="form.status"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            @change="form.employee = null"
            :disabled="isEdit"
            v-b-tooltip.hover title="Active employee under CV Garuda Infinity Kreasindo"
            :value="true"
          >
            Internal
          </b-form-radio>
          <b-form-radio
            v-model="form.status"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            @change="form.employee = null"
            :disabled="isEdit"
            v-b-tooltip.hover title="Outsourced employee of CV Garuda Infinity Kreasindo"
            :value="false"
          >
            External
          </b-form-radio>
        </b-form-group>
        <validation-provider
          v-if="form.status"
          name="Employee"
          :rules="form.status ? 'required' : ''"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Employee*"
            :invalid-feedback="errors[0]"
          >
            <custom-select
              v-model="form.employee"
              label="fullName"
              placeholder="Select employee"
              @search="fetchListEmployee($event)"
              :options="employeeList"
              :disabled="isEdit"
              :class="{ 'is-invalid': errors.length }"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-if="!form.status"
          name="Employee"
          :rules="!form.status ? 'required' : ''"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Employee*"
            :invalid-feedback="errors[0]"
          >
            <b-form-input
              placeholder="Enter name"
              v-model="form.employee"
              trim
              :class="{ 'is-invalid': errors.length }"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-if="!form.status"
          name="Client"
          :rules="!form.status ? 'required' : ''"
          v-slot="{ errors }"
        >
          <b-form-group
            label="Client*"
            :invalid-feedback="errors[0]"
          >
            <custom-select
                v-model="form.client"
                label="name"
                placeholder="Select client"
                :options="clientList"
                :reduce="({ id }) => id"
                :class="{ 'is-invalid': errors.length }"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
            name="Rate Per Day"
            rules="required|min_value:0"
            v-slot="{ errors }"
          >
            <b-form-group label="Rate Per Day*" :invalid-feedback="errors[0]">
              <b-input-group
                prepend="Rp"
                :class="{ 'is-invalid': errors.length }"
              >
                <money
                  class="form-control"
                  :class="{ 'is-invalid': errors.length }"
                  v-model="form.ratePerDay"
                  placeholder="0"
                />
              </b-input-group>
            </b-form-group>
          </validation-provider>
        <b-button v-if="!isEdit" class="next" variant="warning" block @click="submit">
          Save
          <b-spinner
            v-if="isBusy"
            class="ml-1"
            label="Spinning"
            small
          />
        </b-button>
        <div v-else class=" d-flex justify-content-center">
          <b-button
            class="text-warning mt-2"
            variant="outline-warning"
            @click="cancel"
            block
          >
            Cancel
          </b-button>
          <b-button
            class="next ml-3"
            variant="warning"
            @click="submit"
            block
          >
            Update
            <b-spinner
                v-if="isBusy"
                class="ml-1"
                label="Spinning"
                small
            />
          </b-button>
        </div>
      </validation-observer>
    </b-modal>
  </template>

<script>
import api from '@/api'

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: () => false
    },
    form: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    isBusy: false,
    employeeList: [],
    clientList: []
  }),

  created() {
    this.fetchListEmployee('')
    this.fetchClientList()
  },

  methods: {
    async fetchListEmployee(key) {
      const { data } = await api.employee.list({
        page: 1,
        pageSize: 10,
        Filters: key ? `Status==True, fullName@=*${key}` : 'Status==True'
      })

      this.employeeList = data.data.length ? data.data : []
    },
    async fetchClientList() {
      const { data } = await api.client.list()
      this.clientList = data.data.length ? data.data : []
    },
    async submit() {
      this.isBusy = true
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.isBusy = false
        return false
      }

      const form = {
        clientId: this.form.client.id || this.form.client,
        employeeId: this.form.employee.id || null,
        fullname: this.form.employee.fullName || this.form.employee,
        status: this.form.status,
        ratePerDay: this.form.ratePerDay
      }

      this.$emit('submit', { id: this.form.id || null, form: form })
      this.isBusy = false
    },
    resetModal() {
      this.$emit('reset')
    },
    cancel() {
      this.$bvModal.hide('modal-developer')
    }
  }
}
</script>

  <style lang="scss" scoped>
  </style>
