var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('h6',{staticClass:"mb-4 font-weight-bold"},[_vm._v("Filter")]),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Employee"}},[_c('b-form-input',{attrs:{"placeholder":"Select employee"},on:{"change":function($event){return _vm.$refs.table.refresh()}},model:{value:(_vm.filter.fullname),callback:function ($$v) {_vm.$set(_vm.filter, "fullname", $$v)},expression:"filter.fullname"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Client"}},[_c('custom-select',{attrs:{"label":"name","placeholder":"Select client","options":_vm.clientList,"reduce":function (ref) {
	var name = ref.name;

	return name;
}},on:{"input":function($event){return _vm.$refs.table.refresh()}},model:{value:(_vm.filter.clientName),callback:function ($$v) {_vm.$set(_vm.filter, "clientName", $$v)},expression:"filter.clientName"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Status"}},[_c('custom-select',{attrs:{"label":"name","placeholder":"Select status","options":_vm.statusList,"reduce":function (ref) {
	var value = ref.value;

	return value;
}},on:{"input":function($event){return _vm.$refs.table.refresh()}},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('div',{staticClass:"button-project mt-2 d-flex justify-content-end"},[_c('b-button',{staticClass:"reset mr-3",attrs:{"variant":"outline-warning"},on:{"click":_vm.resetFilter}},[_vm._v(" Reset Filter ")]),_c('b-button',{staticClass:"next",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.$refs.table.refresh()}}},[_vm._v(" Apply Filter ")])],1)])],1)],1),_c('b-card',{staticClass:"mt-3",attrs:{"body-class":"py-4 px-3"}},[_c('div',{staticClass:"mt-3 d-flex justify-content-between"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-developer",modifiers:{"modal-developer":true}}],staticClass:"next",attrs:{"variant":"warning"}},[_vm._v(" Add New Developer Rate ")]),_c('b-form-group',{staticClass:"m-0"},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Search..."},on:{"change":function($event){return _vm.$refs.table.refresh()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('br'),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.items.length),expression:"!items.length"}]},[_c('b-col',[_c('div',{attrs:{"align":"center"}},[_c('img',{attrs:{"src":require("@/assets/icon-no-invoice.png")}})])])],1),_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length),expression:"items.length"}],ref:"table",attrs:{"items":_vm.fetchDeveloperList,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"striped":"","responsive":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(employeeName)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullname)+" ")]}},{key:"cell(clientName)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.clientName || '-')+" ")]}},{key:"cell(ratePerDays)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.ratePerDay, { symbol: "Rp.", precision: 0, thousand: ".", }))+" ")]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data"},[_c('b-link',{staticClass:"text-secondary mx-2",on:{"click":function($event){return _vm.goToEdit(item.id)}}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1),_c('b-link',{staticClass:"text-secondary mx-2",on:{"click":function($event){return _vm.goToDelete(item)}}},[_c('fa-icon',{attrs:{"icon":"trash-alt"}})],1)],1)]}}])}),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('span',[_vm._v("Show")]),_c('b-form-select',{staticClass:"page-option ml-2 mr-2 w-auto",attrs:{"options":_vm.pageOptions,"size":"sm"},on:{"change":function($event){return _vm.$refs.table.refresh()}},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('span',[_vm._v("Entries")])],1),_c('div',[_c('b-pagination',{staticClass:"mb-0",attrs:{"per-page":_vm.perPage,"total-rows":_vm.totalRows,"pills":"","hide-goto-end-buttons":""},on:{"input":function($event){return _vm.$refs.table.refresh()}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('span',{staticClass:"show-entries mr-auto"},[_vm._v(" "+_vm._s(("Show " + (_vm.totalRows === 0 ? 0 : ((_vm.currentPage-1) * _vm.perPage) + 1) + " to " + (_vm.totalRows > ((_vm.currentPage) * _vm.perPage) ? ((_vm.currentPage) * _vm.perPage) : _vm.totalRows) + " of " + _vm.totalRows + " entries"))+" ")])],1)])],1),_c('modal-developer',{attrs:{"is-edit":_vm.isEdit,"form":_vm.form},on:{"reset":_vm.resetForm,"submit":_vm.submitDeveloperRate}}),_c('modal-delete-developer',{attrs:{"developer-name":_vm.formDeleted.developerName},on:{"remove":_vm.removeDeveloper}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }